import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./pages/Home";
import Reservation from "./pages/ReservationPage";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<Home />}></Route>
        <Route path="/r/:id" element={<Reservation type="airbnb" />}></Route>
        <Route path="/b/:id" element={<Reservation type="booking" />}></Route>
      </Route>
    </Routes>)

}