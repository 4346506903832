import axios from "axios";
import config from "../config.json"

const API_URL = config.api_url

const headers = { 
    'Content-Type': 'application/json'
}

export default () => {
    return axios.create({
    baseURL: API_URL,
    headers: headers
})};
