// src/i18next.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import des fichiers de traduction
import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';
import esTranslation from './locales/es.json';
import deTranslation from './locales/de.json';
import ptTranslation from './locales/pt.json';
import jaTranslation from './locales/ja.json';
import itTranslation from './locales/it.json';

// Configuration de i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    es: {
      translation: esTranslation,
    },
    de: {
      translation: deTranslation,
    },
    it: {
      translation: itTranslation,
    },
    pt: {
      translation: ptTranslation,
    },
    ja: {
      translation: jaTranslation,
    },
  },
  lng: localStorage.getItem('i18nextLng') || 'fr', // Langue par défaut
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React se charge déjà de l'échappement par défaut
  }
});

export default i18n;
