import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  return (
    <div style={styles.container}>
      <span style={styles.flag} onClick={() => changeLanguage('fr')} title="Français">
        🇫🇷
      </span>
      <span style={styles.flag} onClick={() => changeLanguage('en')} title="English">
        🇬🇧
      </span>
      {/* <span style={styles.flag} onClick={() => changeLanguage('es')} title="Español">
        🇪🇸
      </span>
      <span style={styles.flag} onClick={() => changeLanguage('de')} title="Deutsch">
        🇩🇪
      </span>
      <span style={styles.flag} onClick={() => changeLanguage('pt')} title="Português">
        🇵🇹
      </span>
      <span style={styles.flag} onClick={() => changeLanguage('ja')} title="日本語">
        🇯🇵  
      </span> */}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end', // Aligner à droite
    alignItems: 'center',
    padding: '5px 10px', // Moins de padding
    backgroundColor: '#f8f8f8',
    borderBottom: '1px solid #ddd',
  },
  flag: {
    fontSize: '24px', // Taille des émojis de drapeaux
    margin: '0 5px', // Moins d'espace entre les drapeaux
    cursor: 'pointer', // Indique que c'est cliquable
  },
};

export default LanguageSelector;
