import { useTranslation } from "react-i18next";

export default function ErrorDiv() {

    const { t } = useTranslation();

    return (
        <div>{t('error.message')}</div>
    )

}
