import { useState } from 'react';
import axios from '../api/axios';
import { TailSpin } from 'react-loader-spinner'; // Import du spinner
import { useTranslation } from 'react-i18next'; // Import du hook useTranslation

const ApiButton = ({ link, i18nSection }) => {
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const { t } = useTranslation(); // Utilisation du hook pour la traduction

  const handleClick = async () => {
    setLoading(true);
    setButtonText(''); // Efface le texte du bouton pour afficher le loader

    try {
      const response = await axios().get(link);
      if (response.status === 200) {
        setButtonText(t(`${i18nSection}.ok`)); // Utilisation de la traduction pour 'OK'
      } else {
        setButtonText(t(`${i18nSection}.ko`)); // Utilisation de la traduction pour 'KO'
      }
    } catch (error) {
      setButtonText(t(`${i18nSection}.ko`)); // En cas d'erreur, afficher 'KO' avec traduction
    } finally {
      setLoading(false);

      // Retour au texte initial après 10 secondes
      setTimeout(() => {
        setButtonText(t(`${i18nSection}.title`)); // Retour au texte original avec traduction
      }, 10000);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
      <button
        onClick={handleClick}
        disabled={loading}
        className="button api" // Utilisation des deux classes : 'button' et 'api'
      >
        {loading ? (
          <TailSpin
            height="20"
            width="20"
            color="#ffffff"
            ariaLabel={t(`${i18nSection}.loading`)} // Traduction pour le texte de chargement
          />
        ) : (
          buttonText || t(`${i18nSection}.title`) // Affiche soit le texte dynamique, soit le titre par défaut
        )}
      </button>
    </div>
  );
};

export default ApiButton;
