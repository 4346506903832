import { useEffect, useState } from 'react';
import config from "../config.json"

const WP_BASE_URL = config.wp_base_url

export default function WordPressDiv({ pageId }) {
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`${WP_BASE_URL}/wp-json/wp/v2/pages/${pageId}`);
        const data = await response.json();
        setContent(data.content.rendered);
      } catch (error) {
        console.error('Erreur lors du chargement de la page WordPress:', error);
      }
    };

    if (pageId) {
      fetchContent();
    } else {
      setContent("")
    }
  }, [pageId]);

  return (
    <div>
      <div id="wp_content" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}
