import { forwardRef, useEffect, useRef, useState } from "react"
import axios from "../api/axios"
import WordPressDiv from "../components/WordPressDiv"
import { useParams } from "react-router-dom";
import { format } from 'date-fns'
import { de, enGB, es, fr, it, ja, pt } from 'date-fns/locale'
import ErrorDiv from "../components/ErrorDiv";

import QRCode from 'qrcode.react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSnowflake } from "@fortawesome/free-regular-svg-icons";
import { faBaby, faCoffee, faFan, faShirt, faSquareParking, faUtensils, faCopy, faBed } from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../i18next";
import ApiButton from "../components/ApiButton";

export default function ReservationPage({ type }) {

  const { t } = useTranslation();
  // Définir les locales disponibles
  const locales = {
    fr: fr,
    en: enGB,
    es: es,
    de: de,
    it: it,
    pt: pt,
    ja: ja
  };

  const params = useParams() || {};
  const id = params.id || undefined;

  const [reservation, setReservation] = useState(undefined)
  const [pageId, setPageId] = useState(undefined)
  const [wpItem, setWPItem] = useState(undefined)

  const confirmBox = useRef(null)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isConfirmBoxVisible, setConfirmBoxVisible] = useState(false);

  const fetchData = async () => {
    try {
      let response
      if (type === "airbnb") {
        response = await axios().get("/public/reservation/r/" + id)
      } else if (type === "booking") {
        response = await axios().get("/public/reservation/b/" + id)
      }
      setReservation(response.data)
      if (response.data.property.wp_content) {
        setWPItem(response.data.property.wp_content[0])
        setPageId(getLabel(response.data.property.wp_content[0].postid))
        setConfirmBoxVisible(response.data.arrived == undefined)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    fetchData()

    // Ajouter l'écouteur d'événements lors du montage
    window.addEventListener('resize', handleResize);

    // Nettoyer l'écouteur d'événements lors du démontage
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  useEffect(() => {
    // console.log(wpItem)
    if (wpItem) {
      const i = getLabel(wpItem.postid)
      setPageId(i)
    }
  }, [i18n.language])


  const EventDateBox = ({ startDate, endDate }) => {



    // Récupérer la locale actuelle
    const currentLocale = locales[i18n.language] || fr; // Valeur par défaut à 'fr'

    const formattedStartDate = format(new Date(startDate), "EEEE dd MMMM yyyy", { locale: currentLocale })
    // format(new Date(startDate), "EEEE dd 'de' MMMM 'de' yyyy", { locale: currentLocale }); pour l'espagnol
    const formattedEndDate = format(new Date(endDate), "EEEE dd MMMM yyyy", { locale: currentLocale })

    return (
      <Box>
        <h2>{t("main.date.title")}</h2>
        <div className="line">
          <span className="label">{t("main.date.from")}</span>
          <span className="value">{formattedStartDate}</span>
        </div>
        <div className="line">
          <span className="label">{t("main.date.to")}</span>
          <span className="value">{formattedEndDate}</span>
        </div>
      </Box>
    )
  }

  const handlePWCopy = (e, password) => {
    navigator.clipboard.writeText(password)
  }

  const WifiBox = ({ ssid, password, encryption }) => {

    if (ssid) {

      // Format de la chaîne pour le QR code Wi-Fi
      const wifiString = `WIFI:T:${encryption};S:${ssid};P:${password};;`

      return (
        <Box>
          <h2>{t("main.wifi.title")}</h2>
          <div style={{ textAlign: "center" }}>
            <QRCode
              value={wifiString}
              size={256}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"Q"}
              includeMargin={true}
            />
            <div className="line">
              <span className="label">{t("main.wifi.ssid")}</span>
              <span className="value">{ssid}</span>
            </div>
            <div className="line">
              <span className="label">{t("main.wifi.key")}</span>
              <span className="value">{password}</span>&nbsp;<FontAwesomeIcon icon={faCopy} title={t("main.wifi.copy-title")} onClick={(e) => handlePWCopy(e, password)} />
            </div>
            {/* <div> <a href={wifiString} className="button">
              Rejoindre le réseau
            </a></div> */}
          </div>
        </Box>
      )
    }
  }

  const handleClick = async (event) => {
    event.preventDefault
    try {
      const response = await axios().get("/open/" + reservation.id)
      console.log(response)
    } catch (error) {
      console.log(error);
    }
  }

  const handlePageChange = (event, item) => {
    const i = getLabel(item.postid)
    setWPItem(item)
    setPageId(i)
  }

  const ItemLine = ({ item }) => {
    let label = item.location
    let value = item.value
    if (item.type == 0) {
      if (item.call_no) {
        const fixedCode = (<a className="button" href={'tel:' + item.call_no}>{item.call_no}</a>)
        value = <Trans i18nKey="main.access.phone-me" components={{ strong: fixedCode }} />
      } else {
        // nothing to do
      }
    } else if (item.type == 1) {
      value = reservation.code ? reservation.code.replace(/\s+/g, '').match(/.{1,3}/g).join(' ') : t("main.access.undefined")
    } else if (item.type == 2) {
      if (item.url_in_sms) {
        value = <ApiButton link={"/open/" + reservation.id} i18nSection="button" />
      } else {
        // nothing to do
      }
    } else if (item.type == 3) {
      value = reservation.code ? reservation.code.replace(/\s+/g, '').match(/.{1,3}/g).join(' ') + t("main.access.igloohome-then") : t("main.access.undefined")
    }
    return <div className="line"><span className="label">{label}</span><span className="value">{value}</span></div>

  }

  const Container = ({ children }) => (<div className="container">{children}</div>)
  const Column = ({ children }) => (<div className="column">{children}</div>)
  const Box = forwardRef((props, ref) => (<div className="panel" ref={ref}>{props.children}</div>))

  const AddressBox = ({ property }) => (
    <Box>
      <h2>{t("main.address.title")}</h2>
      <div>{property.address}</div>
      {property.add_comp ?? <div>{property.add_comp}</div>}
      <div>{property.cp} {property.city}</div>
    </Box>)

  const RIBox = ({ property }) => (
    <Box>
      <div className="line">{t("main.hr.no-smoking")}</div>
      <div className="line">{t("main.hr.no-party")}</div>
      <div className="line">{t(property.animals_allowed ? "main.hr.pets-allowed" : "main.hr.no-pets")}</div>
    </Box>
  )

  const HoursBox = ({ wished_checkin, wished_checkout }) => (
    <Box>
      <h2>{t("main.schedule.title")}</h2>
      <div className="line">
        <span className="label">{t("main.schedule.checkin")}</span><span className="value">{t("main.schedule.from")} {wished_checkin}</span>
      </div>
      <div className="line">
        <span className="label">{t("main.schedule.checkout")}</span><span className="value">{t("main.schedule.to")} {wished_checkout}</span>
      </div>
    </Box>
  )

  const EquipmentBox = ({ property }) => (
    <Box>
      <h2>{t("main.amenities.title")}</h2>
      <div className="line"><FontAwesomeIcon icon={faBed} />&nbsp;{t("main.amenities.sheets_and_towels")}</div>
      {property.coffee && <div className="line"><FontAwesomeIcon icon={faCoffee} />&nbsp;{property.coffee.label}</div>}
      {property.dishwasher == 1 && <div className="line"><FontAwesomeIcon icon={faUtensils} title={t("main.amenities.dishwasher")} size="xl" />&nbsp;{t("main.amenities.dishwasher")}</div>}
      {property.washing_machine == 1 && <div className="line"><FontAwesomeIcon icon={faShirt} title={t("main.amenities.washing-machine")} size="xl" />&nbsp;{t("main.amenities.washing-machine")}</div>}
      {property.clim == 1 && <div className="line"><FontAwesomeIcon icon={faSnowflake} title={t("main.amenities.clim")} color='blue' size="xl" />&nbsp;{t("main.amenities.clim")}</div>}
      {property.ventilateur == 1 && <div className="line"><FontAwesomeIcon icon={faFan} title={t("main.amenities.fan")} color="#88f" size="xl" />&nbsp;{t("main.amenities.fan")}</div>}
      {property.lit_parapluie == 1 && <div className="line"><FontAwesomeIcon icon={faBaby} title={t("main.amenities.lit-parapluie")} size="xl" />&nbsp;{t("main.amenities.lit-parapluie")}</div>}

      {property.parking == 1 && <div className="line"><FontAwesomeIcon icon={faSquareParking} title={t("main.amenities.parking")} color='blue' size="xl" />&nbsp;{t("main.amenities.parking")}</div>}
    </Box>
  )

  const AccessBox = ({ reservation }) => {
    return reservation.property.entries.length > 0
      ?
      <Box>
        <h2>{t("main.access.title")}</h2>
        {reservation.property.entries.map((item, idx) => <ItemLine key={"e" + idx} item={item} />)}
      </Box>
      :
      <Box>
        <h2>{t("main.access.title")}</h2>
        <div><em>{t("main.access.not-yet")}</em></div>
      </Box>

  }

  const handleConfirmation = async (event) => {
    event.preventDefault()
    if (confirmBox.current) {
      const response = await axios().post("/public/reservation/", { id: reservation.id, arrived: 1 })
      confirmBox.current.classList.add('fade-out-div');
      confirmBox.current.classList.add('fade-out');

      // Attendre la fin de l'animation avant de supprimer le div
      setTimeout(() => {
        setConfirmBoxVisible(false);
      }, 500); // Durée de la transition en millisecondes

    }
  }

  const ConfirmBox = ({ reservation }) => {
    // <StarRating onRatingSelect={handleRatingSelect} />



    return isConfirmBoxVisible && reservation.available
      ?
      <Box ref={confirmBox}>
        <h2>{t("main.confirm.title")}</h2>
        <div>{t("main.confirm.text")}</div>
        <div style={{ textAlign: "center" }}><span className="button" onClick={handleConfirmation}>{t("main.confirm.button-text")}</span></div>
      </Box>
      :
      ""

  }

  const handleRatingSelect = (rating) => {
    console.log(`Note sélectionnée : ${rating}`);
  };

  function getLabel(label) {
    if (typeof label === 'object') {
      // Si `label` est un objet, on vérifie les langues dans l'ordre : i18n.language -> en -> fr
      return label[i18n.language] || label['en'] || label['fr'] || '';
    }
    // Si `label` n'est pas un objet, on retourne la valeur brute
    return label;
  }

  if (reservation && !reservation.error) {
    // console.log(reservation.property.entries)
    return (<>
      <h2>{t('main.hello', { name: reservation.traveler })}</h2>
      <h3>{t('main.thanks-for-coming', { city: reservation.property.city })}</h3>
      {/* <div>Phone {reservation.phone}.</div> */}

      <Container>
        <Column>
          <AddressBox property={reservation.property} />
          <RIBox property={reservation.property} />
          <EquipmentBox property={reservation.property} />
          <AccessBox reservation={reservation} />
          {windowWidth > 768 ? <ConfirmBox reservation={reservation} /> : ""}
        </Column>
        <Column>
          <EventDateBox
            startDate={reservation.dt_from}
            endDate={reservation.dt_to}
          />
          <HoursBox
            wished_checkin={reservation.wished_checkin}
            wished_checkout={reservation.wished_checkout}
          />
          <WifiBox
            ssid={reservation.property.wifi_ssid}
            password={reservation.property.wifi_key}
            encryption="WPA" // Options: WEP, WPA, nopass (for open networks)
          />
          {windowWidth <= 768 ? <ConfirmBox reservation={reservation} /> : ""}
        </Column>
      </Container>


      {reservation.property.wp_content && <>
        <h3>{t('main.some-precisions')}</h3>
        <div id="rinav">
          {reservation.property.wp_content.map((item, idx) => {
            return <span className="button" key={"b" + idx} onClick={(event) => handlePageChange(event, item)}>{getLabel(item.label)}</span>
          }
          )}
        </div>
        <WordPressDiv pageId={pageId} />
      </>
      }


    </>)
  } else {
    return <ErrorDiv />
  }


}