import { Outlet } from "react-router-dom";
import "./App.sass"
import LanguageSelector from "../components/LanguageSelector";

export default function Layout() {

  return (
    <>
    
    <main className="main">
      <LanguageSelector />
      <Outlet />
    </main>
    </>
  )

}